import moment from "moment";
import { Auth } from "@aws-amplify/auth";
import { diffAssociations, getImageUrl, addPortalUserInfoToUrl, seedIdCheck, generateGuid, addAssociations } from "./Utils";
import { OPERATIONAL_CONFIG_URL, OPERATIONAL_COMPOSER_URL, OPERATIONAL_MODEL_CONFIG_URL, OPERATIONAL_PAGE_CONFIG_URL } from "../constants";
import * as constants from "../constants";

import {DATETIME_FORMAT} from "../constants";

export async function getCurrentToken(): Promise<string> {
    const session = await Auth.currentSession();

    return session.getAccessToken().getJwtToken();
}

export async function getUsername(): Promise<string> {
    const userInfo = await Auth.currentUserPoolUser();

    return userInfo.username;
}

export function getHostURL(
    customerConfig: CustomerConfig
): string {
    return `https://${customerConfig.slug}.${constants.BUILD_ENVIRONMENT}.thefilter.com/v0`;
}

export function getMetadataURL(
    customerConfig: CustomerConfig
): string {
    return `${getHostURL(customerConfig)}/metadata`;
}

export function getInfoRandomURL(
    customerConfig: CustomerConfig
): string {
    return `${getHostURL(customerConfig)}/info/random/{topic}?count=100`;
}


export function getMLTURL(
    customerConfig: CustomerConfig
): string {
    return `${getHostURL(customerConfig)}/slots/${customerConfig.mltSlot}/items`;
}


export function getSearchURL(
    customerConfig: CustomerConfig
): string {
    return `${getHostURL(customerConfig)}/slots/${customerConfig.searchSlot}/items`;
}

export function getCollectionsURL(
    customerConfig: CustomerConfig
): string {
    return `${getHostURL(customerConfig)}/slots/${customerConfig.collectionsSlot}/items`;
}


export async function createRequest(
    url: string,
    method: string,
    body?: string,
    headers?: Record<string, any>
): Promise<Request> {

    const token = await getCurrentToken();

    let requestHeaders: Record<string, string> = {};
    requestHeaders["Authorization"] = `Bearer ${token}`;
    requestHeaders["Content-Type"] = "application/json";
    requestHeaders["Origin"] = window.location.hostname;

    if (constants.API_KEY) {
        requestHeaders["x-api-key"] = constants.API_KEY;
    }

    if (headers !== undefined) {
        requestHeaders = {...requestHeaders, ...headers};
    }

    const options: Record<string, string|Record<string,string>> = {
        method,
        headers: requestHeaders
    };

    if (body) {
        options["body"] = body;
    }

    return new Request(
        // To deal with MGM+ as a query string parameter
        encodeURI(url).replace("+", "%2B"),
        options
    );
}

export async function getItemMetadata(
    customerConfig: CustomerConfig,
    itemId: string): Promise<Response>  {

    const metadataBody = `[{"id": "${seedIdCheck(customerConfig.name,itemId)}"}]`;
    const metadataRequest = await createRequest(getMetadataURL(customerConfig), "POST", metadataBody);

    return fetch(metadataRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getMultiItemMetadata(
    customerConfig: CustomerConfig,
    itemIds: Array<string>): Promise<Response>  {

    const metadataBody = itemIds.map(itemId => {
        return {"id": `${seedIdCheck(customerConfig.name, itemId)}`};
    });

    const metadataRequest = await createRequest(getMetadataURL(customerConfig), "POST", JSON.stringify(metadataBody));

    return fetch(metadataRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getHeroItemMetadata(
    customerConfig: CustomerConfig,
    items: Array<string>
): Promise<SearchItem[]> {

    const metadataRequest = await createRequest(getMetadataURL(customerConfig), "POST", JSON.stringify(items));

    return fetch(metadataRequest).then((response) => {
        if (response.ok) {
            return response.json().then((response) => {
                const heroItems = response.items.map((item: Record<string, any>) => {
                    let genre = "";
                    if (customerConfig.name.toLowerCase() === "uktv")  {
                        if (item.hasOwnProperty("custom")) {
                            const custom = item["custom"];
                            if (custom.hasOwnProperty("category")) {
                                genre = item["custom"]["category"];
                            }
                        }
                        item["id"] = seedIdCheck(customerConfig.name, item.id);
                    } else if (item.hasOwnProperty("genre")) {
                        genre = item["genre"];
                    }

                    return {
                        id: item.id,
                        name: item.name,
                        typeName: item.typeName,
                        image: getImageUrl(item),
                        genre
                    };
                });

                return Promise.resolve(heroItems);
            });
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getGenres(customerConfig: CustomerConfig ): Promise<Response>  {

    const metadataBody = "[{\"id\": \"genres\"}]";
    const metadataRequest = await createRequest(getMetadataURL(customerConfig) + "?lite=true", "POST", metadataBody);

    return fetch(metadataRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getMLT(
    customerConfig: CustomerConfig,
    item: Record<string, any>,
    username: string,
    groups: string[]): Promise<Record<string, any>[]>  {

    const itemId = item["id"];

    let mltURL = `${getMLTURL(customerConfig)}?_debug_metadata=true&ignore=testtool&userId=1234&seedIds=${itemId}`;

    if (customerConfig.name === "UKTV" && (item["space"] !== undefined && item["space"] !== null)) {
        mltURL += `&space=${item["space"]}`;
    }

    mltURL = addPortalUserInfoToUrl(mltURL, username, groups);

    const mltRequest = await createRequest(mltURL, "GET");

    return fetch(mltRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON.items);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getItemsPerSelectedEntity(
    customerConfig: CustomerConfig,
    entityName: string,
    entityType: string,
    username: string,
    groups: string[]): Promise<Record<string, any>[]> {

    let selectedTagUrl = `${getHostURL(customerConfig)}/slots/${customerConfig.entitySlot}/items?_debug_metadata=true&entityType=${entityType}&entityName=${entityName}`;

    if (customerConfig.name === "UKTV") {
        selectedTagUrl += "&space=brand";
    }

    selectedTagUrl = addPortalUserInfoToUrl(selectedTagUrl, username, groups);

    const selectedTagRequest = await createRequest(selectedTagUrl, "GET");
    const response = await fetch(selectedTagRequest);

    if (response.ok) {
        const responseJSON = await response.json();
        return responseJSON.items;
    }

    throw new Error(`Request failed with status ${response.status}`);
}

export async function canonicalIdLookup(
    customer: string,
    itemId: string): Promise<Record<string, any>> {

    const canonicalIdUrl = constants.OPERATIONAL_CANONICAL_ID_URL + `?itemId=${itemId}&customer=${customer}`;
    const canonicalIdRequest = await createRequest(canonicalIdUrl, "GET");

    return fetch(canonicalIdRequest).then(async (response) => {
        if (response.ok) {
            const responseJSON = await response.json();
            return Promise.resolve(responseJSON.ids);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function entityTypeaheadSearch(
    entityType: string,
    queryString: string,
    resultCount?: number
): Promise<EntityAssociation[]> {
    let searchUrl = `${constants.OPERATIONAL_SEARCH_URL}?q=${queryString}`;

    if (entityType) {
        searchUrl += `&entityTypeWhitelist=${entityType}`;
    }

    if (resultCount) {
        searchUrl += `&size=${resultCount}`;
    }

    const searchRequest = await createRequest(searchUrl, "GET");

    return fetch(searchRequest).then(async (response) => {
        if (response.ok) {
            const responseJSON = await response.json().then((response) => {
                return response.items;
                }).then((items) => {
                    return items.map((item: Record<string, any>) => {
                        return {
                            entityId: item.entity.id,
                            entityName: item.entity.name,
                            entityType: item.entity.typeName,
                            entityWeight: constants.DEFAULT_ENTITY_WEIGHT
                        };
                    });
                });
            return Promise.resolve(responseJSON);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function entitySearch(
    entityType: string,
    queryString: string,
    namespace?: string,
    resultCount?: number
): Promise<Entity[]> {
    let searchUrl = `${constants.OPERATIONAL_SEARCH_URL}?q=${queryString}`;

    if (entityType) {
        searchUrl += `&entityTypeWhitelist=${entityType}`;
    }

    if (namespace) {
        searchUrl += `&namespace=${namespace}`;
    }

    if (resultCount) {
        searchUrl += `&size=${resultCount}`;
    }

    const searchRequest = await createRequest(searchUrl, "GET");

    return fetch(searchRequest).then(async (response) => {
        if (response.ok) {
            const responseJSON = await response.json().then((response) => {
                return response.items;
                }).then((items) => {

                return items.map((item: Record<string, any>) => {
                    let alternateName = item.entity.alternateName;
                    if (alternateName !== undefined) {
                        alternateName = alternateName.join(", ");
                    } else {
                        alternateName = [];
                    }

                    return {
                        id: item.entity.id,
                        name: item.entity.name,
                        type: item.entity.typeName,
                        alternateName: alternateName,
                        description: item.entity.description,
                        notes: item.entity.notes,
                        namespace: item.entity.namespace,
                        dateCreated: item.dateCreated,
                        createdBy: item.createdBy,
                        dateUpdated: item.dateCreated !== item.dateUpdated ? item.dateUpdated : "",
                        updatedBy: item.dateCreated !== item.dateUpdated ? item.lastUpdateBy : "",
                        weight: item.entity.weight
                    };
                });

            });
            return Promise.resolve(responseJSON);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getAssociations(
    customerConfig: CustomerConfig,
    seedId: string
): Promise<Response> {

    seedId = seedIdCheck(customerConfig.name, seedId);
    const associationsURL = `${getHostURL(customerConfig)}/entity/association?thingId=${seedId}`;
    const associationRequest = await createRequest(associationsURL, "GET");

    return fetch(associationRequest);
}

export async function getAssociationsByEntityId(
    customerConfig: CustomerConfig,
    entityId: string
): Promise<Response> {

    const associationsURL = `${getHostURL(customerConfig)}/entity/association?entityId=${entityId}`;
    const associationRequest = await createRequest(associationsURL, "GET");

    return fetch(associationRequest);
}

export async function createEntitiesByAssociation(
    entities: EntityAssociation[]
): Promise<EntityAssociation[]> {
    const username = await getUsername();
    const timestamp = moment(new Date().toUTCString()).format(DATETIME_FORMAT);
    const entityBody = entities.map(entity => {
        return {
            createdBy: username,
            lastUpdateBy: username,
            dateCreated: timestamp,
            dateUpdated: timestamp,
            entity: {
                name: entity.entityName,
                typeName: entity.entityType
            }
        };
    });

    const creationRequest = await createRequest(
        constants.OPERATIONAL_ENTITY_CREATION_URL,
        "POST",
        JSON.stringify(entityBody)
    );

    return fetch(creationRequest).then(async (response) => {
        if (response.ok) {
            return response.json().then(responseJSON => {
                return responseJSON.items.map((item: Record<string, any>) => {
                    return {
                        entityId: item.entity.id,
                        entityName: item.entity.name,
                        entityType: item.entity.typeName
                    };
                });
            });
        } else {
            return Promise.reject(response);
        }
    });
}

export async function createOrUpdateEntities(
    entities: Entity[],
    update?: boolean
): Promise<Entity[]> {
    const username = await getUsername();
    const timestamp = moment(new Date().toUTCString()).format(DATETIME_FORMAT);
    const entityBody = entities.map(entity => {
        return {
            createdBy: update ? entity.createdBy : username,
            lastUpdateBy: username,
            dateCreated: update ? entity.dateCreated : timestamp,
            dateUpdated: timestamp,
            entity: {
                id: update ? entity.id : null,
                name: entity.name,
                typeName: entity.type,
                alternateName: entity.alternateName,
                description: entity.description,
                notes: entity.notes,
                namespace: entity.namespace
            }
        };
    });

    const creationRequest = await createRequest(
        constants.OPERATIONAL_ENTITY_CREATION_URL,
        "POST",
        JSON.stringify(entityBody)
    );


    return fetch(creationRequest).then(async (response) => {
        if (response.ok) {
            return response.json().then(responseJSON => {
                return responseJSON.items.map((item: Record<string, any>) => {
                    return {
                        entityId: item.entity.id,
                        entityName: item.entity.name,
                        entityType: item.entity.typeName,
                        entityWeight: item.entity.weight
                    };
                });
            });
        } else {
            return Promise.reject(response);
        }
    });
}

export async function diffAndPostAssociations(
    customerConfig: CustomerConfig,
    thing: Record<string, any>,
    originalEntities: EntityAssociation[],
    updatedEntities: EntityAssociation[],
    origin: string,
    multipleSelected: boolean
): Promise<Response> {
    thing.thingId = seedIdCheck(customerConfig.name, thing.thingId);

    let entitiesToCreate: EntityAssociation[] = [];
    updatedEntities.forEach(entity => {
        if (entity.customOption) {
            entity.thingId = thing.thingId;
            entity.thingType = thing.thingTypeName;
            entity.thingName = thing.thingName;
            entitiesToCreate.push(entity);
        }
    });

    entitiesToCreate = await createEntitiesByAssociation(entitiesToCreate);

    updatedEntities.forEach(entity => {
        if (entity.customOption) {
            const match = entitiesToCreate.find(entityC => {
                return entityC.entityName === entity.entityName && entityC.entityType === entity.entityType;
            });

            if (match) {
                entity.entityId = match.entityId;
                delete entity.customOption;
            }
        }
    });

    let associationsBody;

    if (origin === "multitagging" && multipleSelected) {
        associationsBody = addAssociations(thing, originalEntities, updatedEntities);
    } else {
        associationsBody = diffAssociations(thing, originalEntities, updatedEntities);
    }

    const associationRequest = await createRequest(
        `${getHostURL(customerConfig)}/entity/association`,
        "POST",
        JSON.stringify(associationsBody)
    );

    return fetch(associationRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });

}

export async function postAssociations(
    customerConfig: CustomerConfig,
    entityAssociations: EntityAssociation[]
): Promise<Response> {
    const associationRequest = await createRequest(
        `${getHostURL(customerConfig)}/entity/association`,
        "POST",
        JSON.stringify({ "add": entityAssociations })
    );

    return fetch(associationRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });

}

export async function getEntityAssociations(
    customerConfig: CustomerConfig,
    entityId: string
): Promise<Response> {
    const associationsURL = `${getHostURL(customerConfig)}/entity/association?entityId=${entityId}`;
    const associationRequest = await createRequest(
        associationsURL,
        "GET"
    );

    return fetch(associationRequest);
}

export async function postEntityAssociations(
    customerConfig: CustomerConfig,
    originalEntities: EntityAssociation[],
    updatedEntities: EntityAssociation[]
): Promise<Response> {
    const associationsBody = diffAssociations(null, originalEntities, updatedEntities);
    const associationRequest = await createRequest(
        `${getHostURL(customerConfig)}/entity/association`,
        "POST",
        JSON.stringify(associationsBody)
    );

    return fetch(associationRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });

}

export async function itemSearch(
    customerConfig: CustomerConfig,
    queryString: string
): Promise<SearchItem[]> {

    const searchUrl = `${getSearchURL(customerConfig)}?q=${queryString}&_debug_metadata=true&ignore=testtool`;
    const searchRequest = await createRequest(searchUrl, "GET");

    return fetch(searchRequest).then(async (response) => {
        if (response.ok) {
            const responseJSON = await response.json().then((response) => {
                return response.items;
                }).then((items) => {

                return items.map((item: Record<string, any>) => {
                    let genre = "";
                    if ((customerConfig.name.toLowerCase() === "uktv" || customerConfig.name === "Curiosity Stream") && item.hasOwnProperty("custom")) {
                        const custom = item["custom"];
                        if (custom.hasOwnProperty("category")) {
                            genre = item["custom"]["category"];
                        }
                    } else if (item.hasOwnProperty("genre")) {
                        genre = item["genre"];
                    }

                    return {
                        id: item.id,
                        name: item.name,
                        typeName: item.typeName,
                        image: getImageUrl(item),
                        genre
                    };
            });});
            return Promise.resolve(responseJSON);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getEditorial(
    customerConfig: CustomerConfig,
    slotId: string
): Promise<Response> {

    const editorialUrl = `${getHostURL(customerConfig)}/editorial?slotId=${slotId}`;
    const editorialRequest = await createRequest(editorialUrl, "GET");

    return fetch(editorialRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}


export async function getAllEditorials(
    customerConfig: CustomerConfig
): Promise<Response> {

    const editorialUrl = `${getHostURL(customerConfig)}/editorial`;
    const editorialRequest = await createRequest(editorialUrl, "GET");

    return fetch(editorialRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function deleteEditorial(
    customerConfig: CustomerConfig,
    slotId: string,
    thingId: string
): Promise<Response> {

    const deleteEditorialBody = {
        "slotId": slotId,
        "thingId": thingId
    };

    const editorialRequest = await createRequest(
        `${getHostURL(customerConfig)}/editorial`,
        "DELETE",
        JSON.stringify(deleteEditorialBody)
    );

    return fetch(editorialRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });

}

export async function getAllBlocks(
    customerConfig: CustomerConfig
): Promise<Response> {

    const editorialUrl = `${getHostURL(customerConfig)}/editorial?blocks=true`;
    const editorialRequest = await createRequest(editorialUrl, "GET");

    return fetch(editorialRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function postBlockingEditorial(
    customerConfig: CustomerConfig,
    itemsToBlock: Record<string, any>[]
): Promise<Response> {

    const editorialRequest = await createRequest(
        `${getHostURL(customerConfig)}/editorial`,
        "POST",
        JSON.stringify(itemsToBlock)
    );

    return fetch(editorialRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });

}

export async function makeEditorialLaneSlotRequest(
    customerConfig: CustomerConfig,
    userId: string,
    portalUsername: string,
    portalGroups: string[],
    selectedEditorialID?: string
): Promise<Response> {
    let editorialLaneSlotUrl = `${getHostURL(customerConfig)}/slots/${customerConfig.entitySlot}/items?_debug_metadata=true&ignore=testtool&portalUser=${portalUsername}&portalGroups=${portalGroups}`;

    if (userId && userId.length > 0) {
        editorialLaneSlotUrl += `&userId=${encodeURIComponent(userId.trim())}&`;
    }

    if (selectedEditorialID) {
        editorialLaneSlotUrl += `&entityId=${selectedEditorialID}`;
    }

    const editorialLaneSlotRequest = await createRequest(editorialLaneSlotUrl, "GET");

    return fetch(editorialLaneSlotRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getUserHistory(
    customerConfig: CustomerConfig,
    userId: string,
    brands?: boolean
): Promise<Response> {
    let userHistoryUrl = getHostURL(customerConfig) + "/user/" + encodeURIComponent(userId.trim());

    if (brands) {
        userHistoryUrl += "?grouping=brand";
    }

    const userHistoryRequest = await createRequest(userHistoryUrl, "GET");

    return fetch(userHistoryRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function makeSlotRequest(
    slotUrl: string,
    debugMetadata?: boolean
): Promise<Response> {
    let requestUrl = slotUrl;
    if (debugMetadata && requestUrl.indexOf("?") > -1 ) {
        requestUrl = `${slotUrl}&_debug_metadata=true`;
    } else if (debugMetadata) {
        requestUrl = `${slotUrl}?_debug_metadata=true`;
    }

    const slotRequest = await createRequest(requestUrl, "GET");

    return fetch(slotRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function lookupItemsList(
    customerConfig: CustomerConfig,
    items: string
): Promise<Response> {

    const formattedItems: Record<string,string>[] = [];

    items.split(",").forEach((item: string) => {
        formattedItems.push({
            "id": item
        });
    });

    const metadataRequest = await createRequest(
        getMetadataURL(customerConfig),
        "POST",
        JSON.stringify(formattedItems)
    );

    return fetch(metadataRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getConfig(
    name: string): Promise<Record<string, any>[]>  {

    const configURL = `${OPERATIONAL_CONFIG_URL}?name=${name}`;
    const configRequest = await createRequest(configURL, "GET");

    return fetch(configRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getPortalCustomerConfig(authGroups: string): Promise<Array<CustomerConfig>> {
    const configURL = `${OPERATIONAL_CONFIG_URL}?name=portal_customers`;
    const configRequest = await createRequest(configURL, "GET", undefined, { "authGroups": authGroups});

    return fetch(configRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getPortalCustomerPresets(authGroups: string, customer: string): Promise<Array<Preset>> {
    const configURL = `${OPERATIONAL_CONFIG_URL}?name=portal_presets&customer=${customer}`;
    const configRequest = await createRequest(configURL, "GET", undefined, { "authGroups": authGroups});

    return fetch(configRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getOperationalSlotInfo(
    customerName: string): Promise<Record<string, any>[]>  {

    const configURL = constants.OPERATIONAL_SLOT_INFO_URL.replace("{customer}", customerName);
    const configRequest = await createRequest(configURL, "GET");

    return fetch(configRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

/* Composer Page API calls */
export async function postComposerPage(
    customer: string,
    pageName: string,
    pageDefintion: ComposerElement[],
    pageUserId: string,
    pageSeedId: string,
    editedPageId?: string,
    createdBy?: string,
    dateCreated?: string
): Promise<Response> {
    const username = await getUsername();
    const dateUpdated = moment(new Date().toUTCString()).format(DATETIME_FORMAT);
    const pageId = editedPageId || generateGuid();
    const composerPageBody = {
        pageId,
        customer,
        pageName,
        pageUserId,
        pageSeedId,
        "pageDefinition": JSON.stringify(pageDefintion),
        "dateCreated": dateCreated || dateUpdated,
        "createdBy": createdBy || username,
        "dateUpdated": dateUpdated,
        "lastUpdatedBy": username
    };

    const composerPageRequest = await createRequest(
        `${OPERATIONAL_COMPOSER_URL}/${pageId}`,
        "POST",
        JSON.stringify(composerPageBody)
    );

    return fetch(composerPageRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });

}

export async function getCustomersForComposer(): Promise<string[]>  {
    const customerComposerRequest = await createRequest(`${OPERATIONAL_COMPOSER_URL}`, "GET");

    return fetch(customerComposerRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["result"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getComposerPagesByCustomer(
    customer: string
): Promise<Record<string, any>[]>  {

    const composerPageURL = `${OPERATIONAL_COMPOSER_URL}?customer=${customer}`;
    const composerPageRequest = await createRequest(composerPageURL, "GET");

    return fetch(composerPageRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["result"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getComposerPageById(pageId: string): Promise<Record<string,any>>  {

    const composerPageURL = `${OPERATIONAL_COMPOSER_URL}/${pageId}`;
    const composerPageRequest = await createRequest(composerPageURL, "GET");

    return fetch(composerPageRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["result"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function deleteComposerPageById(pageId: string): Promise<Record<string,any>>  {

    const composerPageURL = `${OPERATIONAL_COMPOSER_URL}/${pageId}`;
    const composerPageRequest = await createRequest(composerPageURL, "DELETE");

    return fetch(composerPageRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["result"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

/* Slots Editor/Preview API calls */
export async function getSlotsForCustomer(
    customerConfig: CustomerConfig
): Promise<Array<Slot>>  {

    const slotsRequest = await createRequest(getHostURL(customerConfig) + "/slots", "GET");

    return fetch(slotsRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["result"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function previewSlot(
    customerConfig: CustomerConfig,
    experiments: string,
    userId?: string,
    seedId?: string,
    searchTerm?: string
): Promise<Response>  {

    let slotPreviewURL = getHostURL(customerConfig) + "/preview?ignore=portal&_debug_metadata=true";
    if (userId)  slotPreviewURL += `&userId=${encodeURIComponent(userId.trim())}`;
    if (seedId)  {
        slotPreviewURL += `&seedIds=${seedId}`;

        // If UKTV add a space to the request
        if (customerConfig.name === "UKTV") {
            slotPreviewURL += "&space=brand";
        }
    }
    if (searchTerm)  slotPreviewURL += `&q=${searchTerm}`;

    const slotPreviewRequest = await createRequest(
        slotPreviewURL,
        "POST",
        experiments
    );

    return fetch(slotPreviewRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function postSlot(
    customerConfig: CustomerConfig,
    slotId: string,
    slotName: string,
    experiments: string,
    fallbackChart: string,
    slot?: Slot
): Promise<Response>  {

    const postSlotURL = getHostURL(customerConfig) + `/slots/${slotId}`;
    const timestamp = moment(new Date().toUTCString()).format(DATETIME_FORMAT);
    const username = await getUsername();
    const createdUser = slot?.createdBy;
    const createdDate = slot?.dateCreated;

    const slotToPost = {
        "slotId": slotId,
        "slotName": slotName,
        "type": slot?.type,
        "experiments": experiments,
        "fallbackChart": fallbackChart,
        "dateCreated": createdDate ? createdDate : timestamp,
        "createdBy": createdUser ? createdUser : username,
        "dateUpdated": timestamp,
        "lastUpdatedBy": username,
        "zeroResultsOk": slot?.zeroResultsOk,
        "isDefault": slot?.isDefault,
        "responseSize": slot?.responseSize,
        "description": slot?.description
    };

    const postSlotRequest = await createRequest(
        postSlotURL,
        "POST",
        JSON.stringify(slotToPost)
    );

    return fetch(postSlotRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

/* Promotions API */
export async function getPromotions(
    customerConfig: CustomerConfig
): Promise<Array<Record<string,any>>> {
    const promotionsRequest = await createRequest(getHostURL(customerConfig) + "/promotion","GET");

    return fetch(promotionsRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["result"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function postPromotion(
    customerConfig: CustomerConfig,
    promotionId: string,
    promotionName: string,
    startDate: string,
    endDate: string,
    slotId: string,
    content: Record<string,any>,
    createdUser?: string,
    createdDate?: string
): Promise<Response>  {

    const promotionsURL = getHostURL(customerConfig) + `/promotion/${promotionId}`;
    const timestamp = moment(new Date().toUTCString()).format(DATETIME_FORMAT);
    const username = await getUsername();

    const promotionToPost = {
        "promotionId": promotionId,
        "promotionName": promotionName,
        "startDate": startDate,
        "endDate": endDate,
        "slot": slotId,
        "content": content,
        "dateCreated": createdDate ? createdDate : timestamp,
        "createdBy": createdUser ? createdUser : username,
        "dateUpdated": timestamp,
        "updatedBy": username
    };

    const postPromotionsRequest = await createRequest(
        promotionsURL,
        "POST",
        JSON.stringify(promotionToPost)
    );

    return fetch(postPromotionsRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function deletePromotion(
    customerConfig: CustomerConfig,
    promotionId: string
    ): Promise<Response>  {
        const promotionsURL = getHostURL(customerConfig) + `/promotion/${promotionId}`;
        const deletePromotionsRequest = await createRequest(promotionsURL, "DELETE");

        return fetch(deletePromotionsRequest).then(async (response) => {
            if (response.ok) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        });
    }

/* Pages API */
export async function getPages(
    customerConfig: CustomerConfig
): Promise<Array<Record<string,any>>> {
    const pagesRequest = await createRequest(`${OPERATIONAL_PAGE_CONFIG_URL}/${customerConfig.slug}`,"GET");

    return fetch(pagesRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["result"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function makePageRequest(
    pageUrl: string,
    debugMetadata?: boolean
): Promise<Response> {
    let requestUrl = pageUrl;
    if (debugMetadata && requestUrl.indexOf("?") > -1 ) {
        requestUrl = `${pageUrl}&_debug_metadata=true`;
    } else if (debugMetadata) {
        requestUrl = `${pageUrl}?_debug_metadata=true`;
    }

    const pageRequest = await createRequest(requestUrl, "GET");

    return fetch(pageRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}


/* Model Config API */
export async function getAllModelConfig(
    customerConfig: CustomerConfig
): Promise<Array<ModelConfig>> {
    const modelConfigURL = `${OPERATIONAL_MODEL_CONFIG_URL}/${customerConfig.slug}`;
    const getAllModelConfigRequest = await createRequest(modelConfigURL, "GET");

    return fetch(getAllModelConfigRequest)
        .then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["result"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getModelConfig(
    customerConfig: CustomerConfig,
    modelConfigType: string
): Promise<ModelConfig> {
    const modelConfigURL = `${OPERATIONAL_MODEL_CONFIG_URL}/${customerConfig.slug}/${modelConfigType}`;
    const getModelConfigRequest = await createRequest(modelConfigURL, "GET");

    return fetch(getModelConfigRequest).then(async (response) => {
        if (response.ok) {
            const responseJSON = await response.json();
            return Promise.resolve(responseJSON["result"]);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function postModelConfig(
    customerConfig: CustomerConfig,
    modelConfigType: string,
    modelConfig?: ModelConfig,
    createdUser?: string,
    createdDate?: string
): Promise<Response|void> {
    if (modelConfig) {
        const modelConfigURL = `${OPERATIONAL_MODEL_CONFIG_URL}/${customerConfig.slug}/${modelConfigType}`;
        const timestamp = moment(new Date().toUTCString()).format(DATETIME_FORMAT);
        const username = await getUsername();

        modelConfig.dateCreated = createdDate ? createdDate : timestamp;
        modelConfig.createdBy = createdUser ? createdUser : username;
        modelConfig.dateUpdated = timestamp;
        modelConfig.lastUpdatedBy = username;

        const postModelConfigRequest = await createRequest(
            modelConfigURL,
            "POST",
            JSON.stringify(modelConfig)
        );

        return fetch(postModelConfigRequest).then(async (response) => {
            if (response.ok) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        });
    }
}

export async function deleteModelConfig(
    customerConfig: CustomerConfig,
    modelConfigId: string
): Promise<Response>  {
    const modelConfigURL = `${OPERATIONAL_MODEL_CONFIG_URL}/${customerConfig.slug}/${modelConfigId}`;
    const deleteModelConfigRequest = await createRequest(modelConfigURL, "DELETE");

    return fetch(deleteModelConfigRequest).then(async (response) => {
        if (response.ok) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

export async function getQuickSightURL(customerConfig: CustomerConfig): Promise<Record<string, string>>  {
    const quicksightUrl = `${constants.OPERATIONAL_QUICKSIGHT_URL}/url?customer=${customerConfig.slug}`;
    const quicksightUrlRequest = await createRequest(quicksightUrl, "GET");

    return fetch(quicksightUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON["body"]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getModelDomainInformation(customerConfig: CustomerConfig): Promise<Array<Record<string, any>>>  {
    const modelDomainUrl =  `${getHostURL(customerConfig)}/info/model`;
    const modelDomainUrlRequest = await createRequest(modelDomainUrl, "GET");

    return fetch(modelDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}


export async function postModelDomainInformation(customerConfig: CustomerConfig, modelBody: Record<string, any>): Promise<Array<Record<string, any>>>  {
    const modelDomainUrl =  `${getHostURL(customerConfig)}/info/model/${modelBody.playlist_name}`;

    const timestamp = moment(new Date().toUTCString()).format(constants.DATE_FORMAT);

    modelBody["audit_info"]["date_created"] = timestamp;
    modelBody["audit_info"]["date_updated"] = timestamp;

    const modelDomainUrlRequest = await createRequest(modelDomainUrl, "PUT", JSON.stringify(modelBody));

    return fetch(modelDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else if (response.status === 404) {
                return Promise.resolve([]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getPageDomainInformation(customerConfig: CustomerConfig): Promise<Array<Record<string, any>>>  {
    const modelDomainUrl =  `${getHostURL(customerConfig)}/info/page`;
    const modelDomainUrlRequest = await createRequest(modelDomainUrl, "GET");

    return fetch(modelDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else if (response.status === 404) {
                return Promise.resolve([]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function postPageDomainInformation(customerConfig: CustomerConfig, pageBody: Record<string, any>): Promise<Array<Record<string, any>>>  {
    const modelDomainUrl =  `${getHostURL(customerConfig)}/info/page/${pageBody.page_name}`;

    const timestamp = moment(new Date().toUTCString()).format(constants.DATE_FORMAT);

    pageBody["audit_info"]["date_created"] = timestamp;
    pageBody["audit_info"]["date_updated"] = timestamp;

    const modelDomainUrlRequest = await createRequest(modelDomainUrl, "PUT", JSON.stringify(pageBody));

    return fetch(modelDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getApiHealthInformation(customerConfig: CustomerConfig): Promise<Array<Record<string, any>>>  {
    const healthDomainUrl =  `${getHostURL(customerConfig)}/info/cloudwatch/api_health`;
    const healthDomainUrlRequest = await createRequest(healthDomainUrl, "GET");

    return fetch(healthDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getHealthDomainInformation(customerConfig: CustomerConfig, domain: string): Promise<Array<Record<string, any>>>  {
    const healthDomainUrl =  `${getHostURL(customerConfig)}/info/${domain}`;
    const healthDomainUrlRequest = await createRequest(healthDomainUrl, "GET");

    return fetch(healthDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getHealthDomainTopicInformation(customerConfig: CustomerConfig, domain: string, topic: string): Promise<Array<Record<string, any>>>  {
    const healthDomainUrl =  `${getHostURL(customerConfig)}/info/${domain}/${topic}`;
    const healthDomainUrlRequest = await createRequest(healthDomainUrl, "GET");

    return fetch(healthDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getChartDomainInformation(customerConfig: CustomerConfig): Promise<Array<Record<string, any>>>  {
    const chartDomainUrl =  `${getHostURL(customerConfig)}/info/chart`;
    const chartDomainUrlRequest = await createRequest(chartDomainUrl, "GET");

    return fetch(chartDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else if (response.status === 404) {
                return Promise.resolve([]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}

export async function getRecFilterDomainInformation(customerConfig: CustomerConfig): Promise<Array<Record<string, any>>>  {
    const chartDomainUrl =  `${getHostURL(customerConfig)}/info/rec_filters`;
    const chartDomainUrlRequest = await createRequest(chartDomainUrl, "GET");

    return fetch(chartDomainUrlRequest).then(async (response) => {
            if (response.ok) {
                const responseJSON = await response.json();
                return Promise.resolve(responseJSON);
            } else if (response.status === 404) {
                return Promise.resolve([]);
            } else {
                return Promise.reject(response);
            }
        }
    );
}