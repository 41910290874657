import React, { ReactNode } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import {
    Trash as DeleteIcon,
    PencilFill as EditIcon
} from "react-bootstrap-icons";
import { getSlotName } from "../../utils/Utils";
import moment from "moment";
import { DATE_FORMAT } from "../../constants";

type BlockingCardProperties = {
    blockedItem: BlockedItem,
    slots: Slot[],
    customerConfig: CustomerConfig,
    confirmRemoveCallback: (blockedItem: BlockedItem) => void,
    confirmEditCallback: (blockedItem: BlockedItem) => void,
}

export default class BlockingCard extends React.Component<BlockingCardProperties> {

    private confirmRemoveBlock = () => {
        this.props.confirmRemoveCallback(this.props.blockedItem);
    }

    private confirmEditBlock = () => {
        this.props.confirmEditCallback(this.props.blockedItem);
    }

    private renderImageAndName = (isPotrait: boolean) => {
        const { blockedItem } = this.props;
        const imageStyle: React.CSSProperties = isPotrait
            ? { width: "auto", height: "100px", paddingRight: "10px", float: "left" }
            : { width: "auto", height: "100px" };

        return (
            <>
                <Card.Img variant="top" src={blockedItem["thingImageUrl"]} style={imageStyle} />
                <b><big>{blockedItem["thingName"]}</big></b>
            </>
        );
    }

    public render(): ReactNode {
        const { blockedItem, slots, customerConfig } = this.props;

        return (
            <Card border="secondary" style={{ width: "100%", minHeight: "370px" }}>
                <Card.Header style={customerConfig.portraitImages ? { textAlign: "center" } : {}}>
                    {customerConfig.portraitImages ? this.renderImageAndName(true) : this.renderImageAndName(false)}
                </Card.Header>
                <Card.Body>
                    <b>Thing ID</b>: {blockedItem["thingId"]} <br />
                    <b>Slot</b>: {getSlotName(blockedItem["slotId"], slots)} <br />
                    <b>Slot ID</b>: {blockedItem["slotId"]} <br />
                    <b>Description</b>: {blockedItem["description"]} <br />
                    <b>Start Date</b>: {moment(blockedItem["startDate"]).format(DATE_FORMAT)} <br />
                    <b>End Date</b>: {moment(blockedItem["endDate"]).format(DATE_FORMAT)}
                </Card.Body>
                <Card.Footer style={{ display: "flex", justifyContent: "end" }}>
                    <ButtonGroup>
                        <Button variant="danger" onClick={this.confirmRemoveBlock}>
                            <DeleteIcon size="20" />
                        </Button>
                        <Button onClick={this.confirmEditBlock}>
                            <EditIcon size="20" />
                        </Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card>
        );
    }
}